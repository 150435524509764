import {
  ref, reactive, // computed, watch, computed, reactive, onMounted,
} from '@vue/composition-api'
import membershipPlanService from '@/http/requests/membership/plan'
import TenantTypeEnum from '@/enums/tenantType.enum'

const customerPlans = ref([])
const subscribedPlan = reactive({
  planId: null,
  chargeSchedule: '',
})

const companyRegistrationForm = reactive({
  tenantType: TenantTypeEnum.INDIVIDUAL.value,
  tenantName: '',
  tenantApiKey: '',
  companyName: '',
  tenantId: '',
  city: '',
  line1: '',
  country: 'USA',
  state: '',
  zipCode: '',
  phoneNumber: '',
  faxNumber: '',
  warehouseName: '',
})

const userRegistrationForm = reactive({
  firstName: '',
  middleName: '',
  lastName: '',
  password: '',
  passwordCheck: '',
  loginName: '',
  email: '',
})

export function useCustomerRegistration() {
  // Takes the customer type after the selection and fetch the related plans by customer type. (INDIVIDUAL,SERVING)
  async function getPlansByCustomerType(type) {
    const result = await membershipPlanService.getPlanPackages(type)
    customerPlans.value = result.data.body
    companyRegistrationForm.tenantType = type
  }

  async function setCustomerType(type) {
    subscribedPlan.planId = null
    subscribedPlan.chargeSchedule = ''
    await getPlansByCustomerType(type)
  }

  function filterPlan(plan) {
    customerPlans.value = customerPlans.value.filter(el => el.code === plan)
  }

  function setChargeSchedule(schedule) {
    subscribedPlan.chargeSchedule = schedule
  }

  function setPlanId(planId) {
    subscribedPlan.planId = planId
  }

  return {
    setCustomerType,
    setPlanId,
    setChargeSchedule,
    filterPlan,
    getPlansByCustomerType,
    customerPlans,
    subscribedPlan,
    companyRegistrationForm,
    userRegistrationForm,
    TenantTypeEnum,
  }
}
