<template>
  <dx-util-popup
    ref="membershipPlanListPopupRef"
    :drag-enabled="false"
    :close-on-outside-click="false"
    :show-close-button="true"
    :show-title="true"
    :title="popupTitle"
    :full-screen="false"
    width="810px"
    height="auto"
    :position="'center'"
    @shown="membershipPlanListPopupShown"
    @hidden="membershipPlanListPopupHidden"
  >
    <div>
      <div v-for="plan in customerPlans" :key="plan.id">
        <div>
          <plan-selection-card :plan="plan" :subscribed-plan-id="subscribedPlanId" @emit-plan-selected="onPlanSelection" />
        </div>
      </div>
    </div>
    <div class="text-right mt-1">
      <dx-util-button text="Cancel" type="danger" style="width:100px" class="mr-1" @click="closePopup" />
      <dx-util-button text="Save" type="success" style="width:100px" @click="handleSubmit" />
    </div>
  </dx-util-popup>
</template>

<script>
// import { Notify } from '@/@robustshell/utils'
import { Notify } from '@/@robustshell/utils'
import membershipSubscriptionService from '@/http/requests/membership/subscription'
import useCurrentUser from '@/libs/app/current-user'
import userManagementService from '@/http/requests/system/userManagementService'
import { useCustomerRegistration } from '@/views/pages/authentication/register-form/useCustomerRegistration'
import { ref } from '@vue/composition-api'

export default {
  components: {
    'plan-selection-card': () => import('@/views/pages/authentication/register-form/components/PlanSelectionCard.vue'),
  },
  props: {
    componentId: {
      type: String,
      default: '',
    },
    tenantType: {
      type: String,
      required: true,
    },
  },
  setup() {
    const { userCompanyId, subscribedMembershipPlans } = useCurrentUser()
    const subscribedPlanId = ref(null)
    if (subscribedMembershipPlans?.length > 0) {
      subscribedPlanId.value = subscribedMembershipPlans[0]?.planId
    }

    const { getPlansByCustomerType, customerPlans } = useCustomerRegistration()

    return {
      userCompanyId,
      getPlansByCustomerType,
      customerPlans,
      subscribedMembershipPlans,
      subscribedPlanId,
    }
  },
  data() {
    return {
      popupTitle: '',
      selectedPlan: {
        planId: null,
        planSchedule: '',
      },
    }
  },
  computed: {
    membershipPlanListPopup() {
      return this.$refs.membershipPlanListPopupRef.instance
    },
  },
  watch: {
    componentId: {
      immediate: true,
      deep: true,
      handler() {
        this.membershipPlanListPopup.show()
        this.getPlansByCustomerType(this.tenantType)
      },
    },
  },
  methods: {
    membershipPlanListPopupShown() {
      this.popupTitle = 'Membership Plan List'
    },
    membershipPlanListPopupHidden() {
    // Clear form when popup is closed
    },
    closePopup() {
      this.membershipPlanListPopup.hide()
    },
    onPlanSelection(plan) {
      this.selectedPlan = plan
    },
    async handleSubmit() {
      if (this.selectedPlan?.planId === null) {
        Notify.warning('Please select a plan to continue')
        return
      }
      const payload = {
        subscriberCompanyId: this.userCompanyId,
        membershipPlanId: this.selectedPlan.planId,
        chargeSchedule: this.selectedPlan.planSchedule,
      }
      await membershipSubscriptionService.create(payload)
      await userManagementService.refreshAuthData()
      window.location.reload()
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
